export const menuItems = [
    
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        permission:"menuitem.show",
        icon: 'ri-dashboard-line',
        badge: {
            variant: "success",
            text: "menuitems.dashboard.badge"
        },
        link: '/'
    },
    {
        id: 1003,
        label: 'menuitems.stores.text',
        permission:"menuitem.show",
        icon: 'ri-store-2-line',
        link: '/stores'
    },
    {
        id: 1011,
        label: 'menuitems.orders.text',
        permission:"menuitem.show",
        icon: 'ri-shopping-cart-2-line',
        link: '/orders'
    },
    {
        id: 1004,
        label: 'menuitems.products.text',
        permission:"menuitem.show",
        icon: 'ri-gift-line',
        subItems: [            
            {
                id: 1008,
                label: 'menuitems.products.text',
                permission:"menuitem.show",
                link: '/products',
            },
            {
                id: 1009,
                label: 'menuitems.productCategories.text',
                permission:"menuitem.show",
                link: '/product-categories',
            },
            {
                id: 1013,
                label: 'menuitems.coupons.text',
                permission:"menuitem.show",
                link: '/coupons',
            }
        ]
    },
    {
        id: 1014,
        label: 'menuitems.cards.text',
        permission:"menuitem.show",
        icon: 'ri-bank-card-line',
        subItems: [            
            {
                id: 10141,
                label: 'menuitems.cardApplications.text',
                permission:"menuitem.show",
                link: '/card-applications',
            },
        ]
    },
    {
        id: 1012,
        label: 'menuitems.customers.text',
        permission:"menuitem.show",
        icon: 'ri-team-fill',
        link: '/customers'
    },
    {
        id: 1007,
        label: 'menuitems.integrations.text',
        permission:"menuitem.show",
        icon: 'ri-share-line',
        subItems: [            
            {
                id: 10071,
                label: 'menuitems.thirdPartyIntegrations.text',
                permission:"menuitem.show",
                link: '/integrations',
            },
            {
                id: 10072,
                label: 'menuitems.appTokens.text',
                permission:"menuitem.show",
                link: '/app-tokens',
            },
        ]
    },
    {
        id: 1008,
        label: 'menuitems.users.text',
        permission:"menuitem.show",
        icon: 'ri-shield-user-fill',
        link: '/users'
    },
    {
        id: 1009,
        label: 'menuitems.settings.text',
        permission:"menuitem.show",
        icon: 'ri-settings-2-line',
        subItems: [            
            {
                id: 1110,
                label: 'menuitems.taxRates.text',
                permission:"menuitem.show",
                link: '/tax-rates',
            },
        ]
    },
]